<template>
	<div class="contact row justify-content-center text-secondary">
		<div class="col-12 col-xl-10 mt-md-3">
			<div class="contact__card">
				<div class="row justify-content-center py-2 py-md-5">
					<div class="col-11 col-md-10 col-lg-7 col-xl-6 my-4">
						<Form @submit="onSubmit" method="post">
							<div class="form-group">
								<label for="FAQClass">問題類別 <span class="text-danger">*</span></label>
								<Field class="form-control mb-1" name="FAQClass" as="select" rules="required" :value="FAQClass">
									<option value="" disabled>請選擇申訴類別</option>
									<option value="投票相關" >投票相關</option>
									<option value="網站異常反映" >網站異常反映</option>
									<option value="其他" >其他</option>
								</Field>
								<ErrorMessage name="FAQClass" />
							</div>
							<div class="form-group">
								<label for="Name">姓名 <span class="text-danger">*</span></label>
								<Field type="text" class="form-control mb-1" id="Name" name="Name"  :value="Name" v-model="Name" placeholder="請輸入" maxlength="16" rules="required"/>
								<ErrorMessage name="Name" />
							</div>
							<div class="form-group">
								<label for="email">電子郵件 <span class="text-danger">*</span></label>
								<Field type="email" class="form-control mb-1" id="Email" name="Email" :value="Email" v-model="Email" placeholder="請輸入" rules="required|email"/>
								<ErrorMessage name="Email" />
							</div>
							<div class="form-group">
								<label for="Phone">電話 <span class="text-danger">*</span></label>
									<Field type="tel" class="form-control mb-1" id="Phone" name="Phone" :value="Phone" v-model="Phone" placeholder="請輸入" maxlength="16" rules="required|numeric"/>
								<ErrorMessage name="Phone" />
							</div>
							<div class="form-group">
								<label for="Des">說明 <span class="text-danger">*</span></label>
								<Field  as="textarea" rows="5" class="form-control mb-1" id="Des" name="Des" :value="Des" v-model="Des" placeholder="請輸入" rules="required"></Field>
								<ErrorMessage name="Des" />
							</div>
							<div class="row justify-content-center pt-3 pb-md-0 pt-md-4">
								<div class="col-6 col-md-4">
									<button type="submit" class="btn btn-lg btn-block btn-info text-white">確認送出</button>
								</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>

		<!-- modal -->
		<transition name="fade">
			<div v-if="showModal" v-bind:class="{ show: showModal }" class="modal modal__recommend">
				<div class="modal-dialog modal-dialog-centered modal-lg">
					<div class="modal-content">
						<div class="modal-header position-relative">
							<h3 class="font-weight-bold">{{submitStatus ? "我們已收到您的申訴" : "送出失敗了......"}}</h3>
							<button @click="showModal = false" type="button" class="close position-absolute m-0 p-0"></button>
						</div>
						<div class="modal-body row justify-content-center text-secondary">
							<div class="col-lg-9">
								<div class="text-center py-3">
									<p>{{submitStatus ? "您的申訴表單已成功送出！" : "請確認您的網路連線後，再重新操作一次。" }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-if="showModal" @click="showModal = false" :class="{ show: showModal }" class="modal__backdrop"></div>
		</transition>
	</div>
</template>

<script>
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('email', email);
defineRule('numeric', numeric);
configure({
  generateMessage: localize('zh_TW', {
    messages: {
      required: '此為必填欄位',
      email: '不是正確的Email',
      numeric: '不是正確的電話號碼',
    },
  }),
});

export default {
	components: {
		Field,
		Form,
		ErrorMessage,
	},
	data() {
		return { 
			showModal: false,
			FAQClass: '',
			Phone: '',
			Name: '',
			Email: '',
			Des: '',
			submitRandom: Math.random(),
			submitStatus: false, 
		}
	},
	/* Function */
	watch: {
		showModal: function () {
		this.showModal ? document.body.classList.add("modal-open") : document.body.classList.remove("modal-open");
		}
	},
	methods: {
		// 送出表單
		onSubmit(values, { resetForm }) {
			//console.log(JSON.stringify(values, null, 2));

			var FD = new FormData();
			FD.append('mode', 'add');

			var objData ={
				"class": "contact",
				"lang": "zh-TW",
				"FAQClass": values.FAQClass,
				"Phone": values.Phone,
				"Name": values.Name,
				"Email": values.Email,
				"Des": values.Des,
				"RandCode": this.submitRandom,
			};

			FD.append('data',JSON.stringify(objData));
			this.axios({
				method: 'post',
				url: 'RApi',
				data: FD,
				headers: {'Content-Type': 'multipart/form-data' }
			})
			.then(response => {
				resetForm();
				if(response.status == 200){    
					this.submitStatus = true;
					this.showModal = true;
					
				} else {
					this.submitStatus = false;
				}
			})
			.catch(function (error) {
				console.log(error);
			});
					
		}
	},
}
</script>